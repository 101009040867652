.footer {
  background-color: var(--color-primary-header);
  padding: 25px;
}
.footer .footer-main {
  max-width: 1200px;
  margin: 0 auto;
}
.footer .footer-core .footer-logo {
  display: block;
  width: 130px;
  height: 60px;
  background: url("/logo.png") no-repeat;
  background-size: contain;
  text-indent: -9999px;
  overflow: hidden;
}
.footer .footer-core > p {
  font-size: 16px;
  color: var(--color-title);
  line-height: 22px;
  margin: 13px 0 20px;
}
.footer .footer-links .footer-links-item {
  display: flex;
  margin-bottom: 20px;
  align-items: baseline;
}
.footer .footer-links .footer-links-item:last-child {
  margin-bottom: 0;
}
.footer .footer-links .footer-links-item dt {
  font-size: 16px;
  font-weight: bold;
  color: var(--color-title);
  line-height: 22px;
  margin-right: 10px;
}
.footer .footer-links .footer-links-item dd {
  margin-right: 8px;
  font-size: 12px;
  color: var(--color-text);
  line-height: 17px;
}
@media (min-width: 768px) {
  .footer-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .footer-main .footer-logo {
    text-align: center;
    letter-spacing: 1px;
  }
  .footer-main .footer-logo img {
    height: 58px;
  }
  .footer-main .footer-logo span {
    margin-bottom: 0;
  }
  .footer-main .footer-links {
    display: flex;
  }
  .footer-main .footer-links .footer-links-item {
    display: block;
    margin-bottom: 0;
    margin-right: 60px;
  }
  .footer-main .footer-links .footer-links-item:last-child {
    margin-right: 0;
  }
  .footer-main .footer-links .footer-links-item dt,
  .footer-main .footer-links .footer-links-item dd {
    margin-right: 0;
  }
  .footer-main .footer-links .footer-links-item dt {
    margin-bottom: 14px;
  }
  .footer-main .footer-links .footer-links-item dd {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .footer-main .footer-links .footer-links-item dd:last-child {
    margin-bottom: 0;
  }
}
@media (min-width: 950px) {
  .footer {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1200px) {
  .footer {
    padding: 20px 0;
  }
}

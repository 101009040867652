sub,
sup {
  line-height: 0;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
ul,
ol,
li,
th,
td,
p,
blockquote,
pre,
form,
fieldset,
legend,
input,
button,
textarea,
hr {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
li {
  list-style: none;
}
fieldset,
img {
  border: 0;
}
input,
textarea {
  outline-style: none;
}
input[type="text"],
input[type="search"],
input[type="password"] {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
textarea {
  resize: none;
}
address,
caption,
cite,
code,
dfn,
em,
i,
th,
var {
  font-style: normal;
  font-weight: normal;
}
abbr,
acronym {
  border: 0;
  font-variant: normal;
}
a {
  text-decoration: none;
  outline: none;
  color: #6b8cc7;
}
body {
  font-family: -apple-system, "PingFang SC", "Helvetica Neue", STHeiti, "Microsoft Yahei", Tahoma, Simsun, sans-serif;
  -webkit-font-smoothing: antialiased;
}
:root {
  --color-page-bg: #fff;
  --color-title: #2f3742;
  --color-title-light: rgba(13,90,254,0.05);
  --color-text: #687583;
  --color-primary: #0d5afe;
  --color-primary-header: #0d5afe;
  --color-module-bg: #fcfdff;
  --color-module-circle: #ebf1ff;
  --color-module-bar: #f9fbff;
}
html.mode-dark {
  --color-page-bg: #100024;
  --color-title: #fff;
  --color-title-light: rgba(87,115,255,0.1);
  --color-text: rgba(255,255,255,0.6);
  --color-primary: #5773ff;
  --color-primary-header: #100024;
  --color-module-bg: #26333c;
  --color-module-circle: rgba(0,0,0,0.15);
  --color-module-bar: #21242f;
}
html,
body {
  background-color: var(--color-page-bg);
  transition: background-color 0.5s ease;
  font-size: 16px;
  line-height: 1.25;
  width: 100%;
  height: 100%;
}
* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
@media (min-width: 1024px) {
  #__next {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
  .container {
    flex: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1200px;
    margin: 0 auto;
    padding: 0;
  }
}

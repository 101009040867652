.react-viewer-mobile {
  position: fixed !important;
  touch-action: none;
  overflow: hidden;
  opacity: 0;
  z-index: 100;
}
.react-viewer-mobile ul {
  margin: 0;
  padding: 0;
}
.react-viewer-mobile li {
  list-style: none;
}
.react-viewer-mobile-mask {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #373737;
  background-color: rgba(55,55,55,0.6);
  height: 100%;
  z-index: 1000;
}
.react-viewer-mobile-canvas {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1005;
}
.react-viewer-mobile-canvas > img {
  display: block;
  margin: 15px auto;
  width: auto;
  height: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.react-viewer-mobile-canvas > img.drag {
  cursor: move;
}
.react-viewer-mobile-canvas-transition {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.react-viewer-mobile-transition {
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}
.react-viewer-mobile-image-transition {
  -webkit-transition-property: width, height, margin, -webkit-transform;
  transition-property: width, height, margin, -webkit-transform;
  transition-property: width, height, margin, transform;
  transition-property: width, height, margin, transform, -webkit-transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

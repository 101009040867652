.header-outer {
  padding: 12px 14px;
  background-color: var(--color-primary-header);
  transition: background-color 0.5s;
}
.header-outer .header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: auto;
}
.header-outer .logo {
  display: block;
  width: 130px;
  height: 60px;
}
.header-outer .logo a {
  display: block;
  width: 100%;
  height: 100%;
  background: url("/logo.png") no-repeat center;
  background-size: contain;
  text-indent: -9999px;
  overflow: hidden;
}
.header-main .navigation-operation {
  display: none;
}
.navigation-operation > button,
.navigation-operation > .button {
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  display: inline-block;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-size: contain;
  outline: none;
  cursor: pointer;
}
.navigation-operation > button + button,
.navigation-operation > .button + button,
.navigation-operation > button + .button,
.navigation-operation > .button + .button {
  margin-left: 43px;
}
.navigation-operation .language {
  background-image: url("https://www.xiaoxili.com/images/icon-language.svg");
}
.navigation-operation .subscribe {
  background-image: url("https://www.xiaoxili.com/images/icon-subscribe.svg");
}
.navigation-pc {
  display: none;
}
.navigation-pc ul {
  display: flex;
}
.navigation-pc li {
  margin-right: 35px;
}
.navigation-pc li:last-child {
  margin-right: 0;
}
.navigation-mobile li > a,
.navigation-pc li > a {
  position: relative;
  font-weight: 500;
  color: #fff;
  line-height: 33px;
  font-size: 14px;
}
.navigation-mobile li > a:after,
.navigation-pc li > a:after {
  content: "";
  display: none;
  position: absolute;
  bottom: -5px;
  right: 0;
  left: 0;
  width: 14px;
  margin: auto;
  background: #f3f3f3;
  height: 2px;
  border-radius: 2px;
}
.navigation-mobile li > a.is-active,
.navigation-pc li > a.is-active {
  background-image: linear-gradient(318deg, #4ab8ff, #b649ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navigation-toggle {
  position: fixed;
  right: 20px;
  top: 17px;
  z-index: 100;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  width: 44px;
  height: 44px;
  transition: all 0.5s;
  background: none;
  border: none;
  outline: none;
}
.navigation-toggle .text {
  position: absolute;
  overflow: hidden;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0px;
  border: 0px;
}
.navigation-toggle:before,
.navigation-toggle:after {
  content: '';
  display: block;
  position: relative;
  width: 24px;
  height: 3px;
  margin: auto;
  background-color: #fff;
  border-radius: 3px;
  transition: 0.5s;
}
.navigation-toggle:before {
  margin-top: 15px;
}
.navigation-toggle:after {
  margin-bottom: 15px;
}
.navigation-toggle.active {
  background-color: rgba(0,0,0,0);
}
.navigation-toggle.active:before {
  transform: translateY(6px) rotate(45deg);
}
.navigation-toggle.active:after {
  transform: translateY(-5px) rotate(-45deg);
}
.navigation-pop {
  pointer-events: none;
  touch-action: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  padding: 31px 31px 31px 40px;
  box-sizing: border-box;
  transition: 0.5s;
  opacity: 0;
}
.navigation-pop.active {
  opacity: 1;
  pointer-events: auto;
}
.navigation-pop.active .navigation-mobile li {
  transform: translate3d(0, 0, 0);
}
.navigation-pop.active .navigation-mobile li:nth-child(1) {
  transition-delay: 0.4s;
}
.navigation-pop.active .navigation-mobile li:nth-child(2) {
  transition-delay: 0.5s;
}
.navigation-pop.active .navigation-mobile li:nth-child(3) {
  transition-delay: 0.6s;
}
.navigation-pop.active .navigation-mobile li:nth-child(4) {
  transition-delay: 0.7s;
}
.navigation-pop.active .navigation-mobile li:nth-child(5) {
  transition-delay: 0.8s;
}
.navigation-pop.active .navigation-operation {
  opacity: 1;
  transition-delay: 1.2s;
}
.navigation-pop .navigation-pop-btn {
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  height: 100%;
  padding: 0;
  border: none;
  outline: none;
  background-color: var(--color-primary-header);
  transition: background-color 0.5s;
  opacity: 0.8;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.navigation-pop .navigation-operation {
  opacity: 0;
  transition: 0.4s;
  position: relative;
}
.navigation-pop .navigation-mobile {
  position: relative;
  margin: 38px 0 36px 0;
  max-width: 400px;
}
.navigation-pop .navigation-mobile li {
  display: block;
  margin-bottom: 29px;
  font-size: 14px;
  transform: translate3d(-100%, 0, 0);
  transition: 0.4s;
}
.navigation-pop .navigation-mobile li:last-child {
  margin-bottom: 0;
}
@media (min-width: 768px) {
  .header-holder {
    height: 84px;
  }
  .header-outer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
  }
  .navigation-toggle,
  .navigation-pop {
    display: none;
  }
  .header-main .navigation-operation {
    display: block;
    margin-right: 10px;
  }
  .header-main .navigation-pc {
    display: block;
  }
}
@media (min-width: 1024px) {
  .header-outer {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (min-width: 1200px) {
  .header-outer {
    padding-left: 0;
    padding-right: 0;
  }
}

.home-main h2 {
  color: var(--color-title);
}
.image-item > div {
  display: block !important;
}
.image-item > img,
.image-item > video {
  max-width: 100%;
  margin: 0 auto;
  height: auto;
  display: block;
}
